<template>
  <div class="ins-page flex-col">
    <div class="ins-main1 flex-col">
      <div class="ins-mod1 flex-col">
        <div class="ins-bd1 flex-col">
          <headInfo :type="type"></headInfo>
          <span class="ins-word3">众多标杆客户的选择</span>
          <span class="ins-word4">一路相伴&nbsp;&nbsp;&nbsp;见证成长</span>
          <!-- <div class="ins-outer2 flex-col" @click="toNewPage(5)">
            <span class="ins-word5">立即试用</span>
          </div> -->
        </div>
        <div class="ins-bd2 flex-col"><div class="ins-bd3 flex-col"></div></div>
      </div>

      <div id="header-ident" name="header-ident" ref="header-ident">
        <!-- @tab-click="handleClick" -->
        <el-tabs v-model="activeName">
          <el-tab-pane label="松云WMS" name="first">
            <div class="iwm-group4 flex-col">
              <div class="iwm-bd3 flex-row justify-between">
                <div class="iwm-main1 flex-col">
                  <img
                    class="iwm-img2"
                    referrerpolicy="no-referrer"
                    src="@/assets/main/camera.png"
                  />
                </div>
                <div class="iwm-main2 flex-col">
                  <div class="iwm-section1 flex-row justify-between">
                    <div class="iwm-outer1 flex-col">
                      <span class="iwm-info7">1</span>
                    </div>
                    <div class="iwm-outer2 flex-col justify-between">
                      <span class="iwm-word6">索尼</span>
                      <div class="iwm-box4 flex-col"></div>
                    </div>
                  </div>
                  <span class="iwm-word7">痛点</span>
                  <span class="iwm-infoBox1"
                    >1.在原系统基础上作业（拣货、出库）效率再提升<br />2.在货位存储量有限不打算扩建仓库的情况下，货品太多，仓库存储位不够</span
                  >
                  <span class="iwm-word8">解决方案</span>
                  <span class="iwm-infoBox2"
                    >1.由传统“人到货”方式变为“货到人”方式，提升作业效率、降低差错率<br />2.采用大型立库，货位翻倍，可存储量翻倍，并配有两台一体机，仓库操作员无需即可完成各种任务，节省人力</span
                  >
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="松云TMS" name="second">
            <div class="itm-main7 flex-col">
              <div class="itm-layer1 flex-row justify-between">
                <div class="itm-group2 flex-col">
                  <img
                    class="itm-img2"
                    referrerpolicy="no-referrer"
                    src="@/assets/main/tmsone.png"
                  />
                </div>
                <div class="itm-group3 flex-col">
                  <div class="itm-outer1 flex-row justify-between">
                    <div class="itm-group4 flex-col">
                      <span class="itm-txt4">1</span>
                    </div>
                    <div class="itm-group5 flex-col justify-between">
                      <span class="itm-txt5">中船黄埔文冲物流</span>
                      <div class="itm-mod1 flex-col"></div>
                    </div>
                  </div>
                  <span class="itm-txt6">痛点</span>
                  <span class="itm-paragraph1"
                    >1.&nbsp;信息链不畅<br />2.&nbsp;承运商较多，承运商信息化水平不一，无法实现配送过程全程可视化<br />3.&nbsp;物流数据分散，不规范，数据挖掘分析能力有限，无法为相关管理决策提供有效的数据支撑</span
                  >
                  <span class="itm-word11">解决方案</span>
                  <span class="itm-paragraph2"
                    >1.&nbsp;配送过程关键节点作业跟踪，细化作业时效监控，及时反馈，实时记录<br />2.&nbsp;松云TMS、RF、司机APP、微信和WebWork接口平台应用打造仓储运输全程物流管理平台<br />3.&nbsp;多种报表覆盖各项功能，数据算法分析，通过订单生产看板实时了解订单的不同状态</span
                  >
                </div>
              </div>
            </div>
            <div class="itm-main8 flex-col">
              <div class="itm-group6 flex-row justify-between">
                <div class="itm-block3 flex-col">
                  <div class="itm-bd1 flex-row justify-between">
                    <div class="itm-box2 flex-col justify-between">
                      <span class="itm-word12">白云机场</span>
                      <div class="itm-main9 flex-col"></div>
                    </div>
                    <div class="itm-box3 flex-col">
                      <span class="itm-txt7">2</span>
                    </div>
                  </div>
                  <span class="itm-word13">痛点</span>
                  <span class="itm-infoBox1"
                    >1.运输方式复杂：国内以及国外航空物流，车辆运输、快递<br />2.承运商多：合作承运商100多家，较难进行统一管理<br />3.业务复杂：涉及到的国外航空物流（进出口清关等）操作复杂、严谨、精准</span
                  >
                  <span class="itm-word14">解决方案</span>
                  <span class="itm-infoBox2"
                    >1.不同运输方式统一模板，信息准确、不易出错、便于管理<br />2.订单自动配载：TMS系统中对订单分段，可以选择多个订单合并配载；在指派规则中录入实际指派承运商的规则，在波次模板中建立波次，自动指派承运商<br />实时跟踪与查询：承运商和南航现场人员可以通过APP，实时得知运输执行的完成情况</span
                  >
                </div>
                <div class="itm-block4 flex-col">
                  <img
                    class="itm-img3"
                    referrerpolicy="no-referrer"
                    src="@/assets/main/feiji.png"
                  />
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="松云SCM" name="third">
            <div class="isc-mod6 flex-col">
              <div class="isc-main1 flex-row justify-between">
                <div class="isc-box1 flex-col">
                  <img
                    class="isc-pic2"
                    referrerpolicy="no-referrer"
                    src="@/assets/main/scmone.png"
                  />
                </div>
                <div class="isc-box2 flex-col">
                  <div class="isc-box3 flex-row justify-between">
                    <div class="isc-section2 flex-col">
                      <span class="isc-word8">1</span>
                    </div>
                    <div class="isc-section3 flex-col justify-between">
                      <span class="isc-word9">深圳吉阳供应链</span>
                      <div class="isc-outer2 flex-col"></div>
                    </div>
                  </div>
                  <span class="isc-txt4">痛点</span>
                  <span class="isc-paragraph1"
                    >1.&nbsp;招投标投入过大<br />2.&nbsp;无法精准管控供应商送货时间和数量<br />3.&nbsp;物料信息太多，杂乱无章</span
                  >
                  <span class="isc-info6">解决方案</span>
                  <span class="isc-paragraph2"
                    >1.&nbsp;招标上云、报价保密、自动比价，一键发布通告，实时更新进度，分析结果数据<br />2.&nbsp;数据算法，实时追踪，全流程协同<br />3.&nbsp;信息条码化，可自定义模版，省时省力，精准不出错</span
                  >
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="松云CRM" name="four">
            <div class="icr-box4 flex-col">
              <div class="icr-section2 flex-row justify-between">
                <div class="icr-main2 flex-col">
                  <img
                    class="icr-img2"
                    referrerpolicy="no-referrer"
                    src="@/assets/main/crmone.png"
                  />
                </div>
                <div class="icr-main3 flex-col">
                  <div class="icr-mod3 flex-row justify-between">
                    <div class="icr-wrap1 flex-col">
                      <span class="icr-txt2">1</span>
                    </div>
                    <div class="icr-wrap2 flex-col justify-between">
                      <span class="icr-word11">中山定宇明</span>
                      <div class="icr-outer3 flex-col"></div>
                    </div>
                  </div>
                  <span class="icr-txt3">痛点</span>
                  <span class="icr-infoBox1"
                    >1.获客难度大<br />2.跟进进度无法清晰可见<br />3.打印物料较多，模板填写繁杂</span
                  >
                  <span class="icr-info5">解决方案</span>
                  <span class="icr-paragraph1"
                    >1.提供精细化公司筛选，同步用户管理，保存跟进结果<br />2.全可视化操作，科学数据看板展现跟进记录，实时分析跟进数据<br />3.模板上云，多表同步，客户和合同信息自动化填入，提高工作效率</span
                  >
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="松云ERP" name="five">
            <div class="ins-mod5 flex-col">
              <div class="ins-group1 flex-row justify-between">
                <div class="ins-block1 flex-col">
                  <img
                    class="ins-pic2"
                    referrerpolicy="no-referrer"
                    src="@/assets/main/erpone.png"
                  />
                </div>
                <div class="ins-block2 flex-col">
                  <div class="ins-box1 flex-row justify-between">
                    <div class="ins-outer3 flex-col">
                      <span class="ins-word10">1</span>
                    </div>
                    <div class="ins-outer4 flex-col justify-between">
                      <span class="ins-word11">道闸产业链</span>
                      <div class="ins-bd4 flex-col"></div>
                    </div>
                  </div>
                  <span class="ins-txt4">痛点</span>
                  <span class="ins-infoBox1"
                    >1.代理商、工程商以及其他渠道客户难统一管理<br />2.成产销售流程不完整、不规范<br />3.库存管理混乱</span
                  >
                  <span class="ins-info4">解决方案</span>
                  <span class="ins-paragraph1"
                    >1.松云ERP给与完整的客户管理方案，管理细节适配各类客户<br />2.销售和生产之间的订单流和客户服务信息集成<br />3.成产原料，成品库存精细化管理，各类报表覆盖各类仓储数据</span
                  >
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="松云MALL" name="six">
            <div class="ima-group6 flex-col">
              <div class="ima-layer2 flex-row justify-between">
                <div class="ima-bd4 flex-col">
                  <img
                    class="ima-pic2"
                    referrerpolicy="no-referrer"
                    src="@/assets/main/mallone.png"
                  />
                </div>
                <div class="ima-bd5 flex-col">
                  <div class="ima-box1 flex-row justify-between">
                    <div class="ima-outer1 flex-col">
                      <span class="ima-word6">1</span>
                    </div>
                    <div class="ima-outer2 flex-col justify-between">
                      <span class="ima-word7">熊猫游轮商城</span>
                      <div class="ima-section1 flex-col"></div>
                    </div>
                  </div>
                  <span class="ima-info3">痛点</span>
                  <span class="ima-infoBox1"
                    >1.&nbsp;原系统太过于简陋，功能不完善，由于公司拓展业务，导致整体系统运营不畅<br />2.&nbsp;产品体验感差，用户不互动，产品传播率低，用户转化率低<br />3.&nbsp;涉及到海外跨境商品订单的流转，进出口关税问题等处理</span
                  >
                  <span class="ima-info4">解决方案</span>
                  <span class="ima-infoBox2"
                    >1.&nbsp;订单管理系统将所有信息统一整合<br />2.&nbsp;高转化营销活动，拼团、砍价、秒杀、限时购、限时折扣、签到、推荐有礼等多种营销活动<br />3.&nbsp;设定模板，统一操作，提高效率、准确率</span
                  >
                </div>
              </div>
            </div>
            <div class="ima-group7 flex-col">
              <div class="ima-layer3 flex-row justify-between">
                <div class="ima-block1 flex-col">
                  <div class="ima-box2 flex-row justify-between">
                    <div class="ima-bd6 flex-col justify-between">
                      <span class="ima-info5">吉利云商城</span>
                      <div class="ima-block2 flex-col"></div>
                    </div>
                    <div class="ima-bd7 flex-col">
                      <span class="ima-word8">2</span>
                    </div>
                  </div>
                  <span class="ima-word9">痛点</span>
                  <span class="ima-paragraph1"
                    >1.汽车零部件商品众多，经销商信息化水平不一，无法实现生产到销售过程全程可视化、透明化、信息化。<br />2.针对汽车服务项目众多、服务门店全国分散，不易于收集用户服务反馈和用户满意度</span
                  >
                  <span class="ima-word10">解决方案</span>
                  <span class="ima-infoBox3"
                    >1.利用数据可视化以及多报表全面覆盖将所有信息整理并展示<br />2.构建统一销售商城平台，方便经销商、直营店、服务门店挂售商品和服务，方便汽车品牌下的用户在平台上根据车型等数据购买匹配的汽车官方配件及检测、保养等服务</span
                  >
                </div>
                <div class="ima-block3 flex-col">
                  <img
                    class="ima-pic3"
                    referrerpolicy="no-referrer"
                    src="@/assets/main/car.png"
                  />
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>

      <div class="ins-mod6 flex-col">
        <!-- <div class="ins-bd5 flex-row">
          <span class="ins-word12">更多案例</span>
        </div>
        <div class="ins-bd6 flex-row justify-between">
          <div class="ins-section1 flex-col">
            <div class="ins-layer1 flex-col"></div>
          </div>
          <div class="ins-section2 flex-col">
            <div class="ins-outer5 flex-col"></div>
          </div>
          <div class="ins-section3 flex-col">
            <div class="ins-section4 flex-col"></div>
          </div>
          <div class="ins-section5 flex-col">
            <div class="ins-group2 flex-col"></div>
          </div>
          <div class="ins-section6 flex-col">
            <div class="ins-box2 flex-col"></div>
          </div>
        </div>
        <div class="ins-bd7 flex-row justify-between">
          <div class="ins-main3 flex-col">
            <div class="ins-outer6 flex-col"></div>
          </div>
          <div class="ins-main4 flex-col">
            <div class="ins-section7 flex-col"></div>
          </div>
          <div class="ins-main5 flex-col">
            <div class="ins-box3 flex-col"></div>
          </div>
          <div class="ins-main6 flex-col">
            <img
              class="ins-pic3"
              referrerpolicy="no-referrer"
              src="@/assets/images/partner/yuntu.png"
            />
          </div>
          <div class="ins-main7 flex-col">
            <div class="ins-mod7 flex-col"></div>
          </div>
        </div>
        <div class="ins-bd8 flex-row justify-between">
          <div class="ins-wrap1 flex-col">
            <div class="ins-main8 flex-col"></div>
          </div>
          <div class="ins-wrap2 flex-col">
            <div class="ins-layer2 flex-col"></div>
          </div>
          <div class="ins-wrap3 flex-col">
            <div class="ins-outer7 flex-col"></div>
          </div>
          <div class="ins-wrap4 flex-col">
            <div class="ins-wrap5 flex-col"></div>
          </div>
          <div class="ins-wrap6 flex-col">
            <div class="ins-bd9 flex-col"></div>
          </div>
          <div class="ins-wrap7 flex-col">
            <div class="ins-box4 flex-col"></div>
          </div>
        </div>
        <img
          class="ins-pic4"
          referrerpolicy="no-referrer"
          src="@/assets/images/partner/backpart.png"
        /> -->
      </div>
      <footInfo style="position: relative" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      type: 3,
      selNum : 'zero',
      activeName: "first",
      constants: {},
    };
  },
  created() {
    if( typeof(this.$route.params.sel) != 'undefined'){
      // alert(this.$route.params.sel)
      this.selNum = this.$route.params.sel;
    }
    this.$nextTick(() => {
      this.toLocal();
    });
  },
  mounted() {
    let _this = this;
    _this.$nextTick(function () {
      window.addEventListener("scroll", _this.handleScroll);
    });
    if(this.selNum != 'zero'){
      this.activeName = this.selNum;
    }
  },
  methods: {
    toLocal() {
      //查找存储的锚点id
      let Id = localStorage.getItem("toId");
      let toElement = document.getElementById(Id);
      //锚点存在跳转
      if (Id) {
        toElement.scrollIntoView();
      }
    },
    // 跳转页面
    toNewPage(index) {
      let url = "";
      // index的类型 0-主页 1-产品介绍 2-服务体系 3-典型案例 4-关于松云 5-预约注册
      switch (index) {
        case 0: //主页
          url = "/";
          break;
        case 1: // 产品介绍
          url = "/product/erp";
          break;
        case 2: // 服务体系
          url = "/system";
          break;
        case 3: //  典型案例
          url = "/instance/erp";
          break;
        case 4: //关于松云
          url = "/about";
          break;
        case 5: //预约注册
          url = "/register";
          break;
      }
      if (url == "") {
        return;
      }
      this.$router.push({ path: url });
    },
  },
  //用完后记得将存储的锚点置空，否则会影响其他页面跳转到当前页面
  destroyed() {
    localStorage.setItem("toId", "");
  },
};
</script>
<style  rel="stylesheet/scss" lang="scss" >
#header-ident .el-tabs .el-tabs__header {
  margin-top: 120px;
}
.el-tabs__nav-wrap::after {
  background-color: rgba(255, 255, 255, 0);
}
.el-tabs__active-bar {
  // height: 5px;
  // background-color: rgba(107, 214, 45, 0.966);
  width: 40px !important;
  height: 6px;
  border-radius: 3px;
  background-color: rgba(37, 184, 47, 1);
  margin: 2px 42px 0 43px;
}

.el-tabs__nav-scroll {
  display: flex;
  justify-content: center;
  .el-tabs__item {
    // padding: 0 20px;
    // color: rgba(46, 92, 19, 0.966);
    // color: brown;
    // width: 140px;
    height: 50px;
    // display: block;
    overflow-wrap: break-word;
    color: rgba(102, 102, 102, 1);
    font-size: 32px;
    letter-spacing: 0.6399999856948853px;
    font-family: AlibabaPuHuiTiR;
    white-space: nowrap;
    line-height: 44px;
    text-align: center;
    padding: 0 37px;
  }
}
.el-tabs__item.is-active {
  // color: rgba(46, 92, 19, 0.966);
  color: rgba(37, 184, 47, 1);
  font-family: AlibabaPuHuiTiM;
}
</style>
<style rel="stylesheet/scss" lang="scss">
.ins-page {
  position: relative;
  width: 1920px;
  // height: 2826px;
  // height: 3314px;
  margin: 0 auto;
  background-color: rgba(250, 250, 250, 1);
  overflow: hidden;
  .ins-main1 {
    width: 1920px;
    // height: 2826px;
    .ins-mod1 {
      // z-index: 11;
      height: 920px;
      overflow: hidden;
      background: url(../../assets/images/back/graythird.png) 100% no-repeat;
      width: 1920px;
      position: relative;
      .ins-bd1 {
        z-index: 80;
        width: 1920px;
        height: 610px;
        .ins-outer1 {
          height: 100px;
          width: 1920px;
          .ins-main2 {
            width: 1775px;
            height: 42px;
            margin: 29px 0 0 65px;
            .ins-pic1 {
              width: 180px;
              height: 38px;
              margin-top: 2px;
            }
            .ins-txt1 {
              width: 43px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 0.88);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 301px;
            }
            .ins-info1 {
              width: 85px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 0.88);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 104px;
            }
            .ins-word1 {
              width: 85px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 0.88);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 104px;
            }
            .ins-word2 {
              width: 85px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiB;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 104px;
            }
            .ins-txt2 {
              width: 85px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 0.88);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 104px;
            }
            .ins-mod2 {
              height: 42px;
              border-radius: 21px;
              border: 2px solid rgba(37, 184, 47, 1);
              margin-left: 346px;
              width: 149px;
              .ins-txt3 {
                width: 85px;
                height: 29px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(255, 255, 255, 0.94);
                font-size: 21px;
                letter-spacing: 0.41999998688697815px;
                font-family: AlibabaPuHuiTiR;
                white-space: nowrap;
                line-height: 29px;
                text-align: left;
                margin: 7px 0 0 32px;
              }
            }
          }
        }
        .ins-word3 {
          width: 470px;
          height: 65px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 52px;
          letter-spacing: 1.0399999618530273px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 65px;
          text-align: center;
          margin: 221px 0 0 725px;
        }
        .ins-word4 {
          width: 262px;
          height: 52px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 28px;
          letter-spacing: 1.75px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 52px;
          text-align: right;
          margin: 36px 0 0 829px;
        }
        .ins-outer2 {
          height: 68px;
          border-radius: 34px;
          background-color: rgba(37, 184, 47, 1);
          box-shadow: 0px 20px 40px 0px rgba(37, 184, 47, 0.32);
          width: 220px;
          margin: 68px 0 0 850px;
          .ins-word5 {
            width: 113px;
            height: 38px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 28px;
            letter-spacing: 0.5600000023841858px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 38px;
            text-align: left;
            margin: 15px 0 0 54px;
          }
        }
      }
      .ins-bd2 {
        z-index: 12;
        height: 920px;
        background: url(../../assets/main/instance.png) 100% no-repeat;
        width: 1920px;
        position: absolute;
        left: 0;
        top: 0;
        .ins-bd3 {
          width: 1920px;
          height: 920px;
          // background-color: rgba(0, 0, 0, 0.5);
        }
      }
    }
    // 开始
    .iwm-group4 {
      height: 428px;
      border: 1px solid rgba(245, 245, 245, 1);
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.04);
      width: 1161px;
      margin: 45px 0 0 329px;
      .iwm-bd3 {
        width: 1122px;
        height: 428px;
        .iwm-main1 {
          z-index: 53;
          position: relative;
          width: 428px;
          height: 428px;
          overflow: hidden;
          background: url(../../assets/images/back/grayfour.png) 100% no-repeat;
          .iwm-img2 {
            z-index: 54;
            position: absolute;
            left: 89px;
            top: 126px;
            width: 224px;
            height: 192px;
          }
        }
        .iwm-main2 {
          width: 669px;
          height: 366px;
          .iwm-section1 {
            width: 147px;
            height: 70px;
            .iwm-outer1 {
              height: 70px;
              background-color: rgba(37, 184, 47, 1);
              width: 64px;
              .iwm-info7 {
                width: 20px;
                height: 44px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(255, 255, 255, 1);
                font-size: 32px;
                letter-spacing: 0.6399999856948853px;
                font-family: AlibabaPuHuiTiM;
                white-space: nowrap;
                line-height: 44px;
                text-align: left;
                margin: 18px 0 0 23px;
              }
            }
            .iwm-outer2 {
              width: 65px;
              height: 41px;
              margin-top: 26px;
              .iwm-word6 {
                width: 49px;
                height: 33px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(51, 51, 51, 1);
                font-size: 24px;
                letter-spacing: 0.47999998927116394px;
                font-family: AlibabaPuHuiTiR;
                white-space: nowrap;
                line-height: 33px;
                text-align: left;
              }
              .iwm-box4 {
                width: 65px;
                height: 2px;
                background-color: rgba(37, 184, 47, 1);
                margin-top: 6px;
              }
            }
          }
          .iwm-word7 {
            width: 33px;
            height: 22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(40, 40, 40, 1);
            font-size: 16px;
            letter-spacing: 0.3199999928474426px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 22px;
            text-align: left;
            margin: 32px 0 0 7px;
          }
          .iwm-infoBox1 {
            width: 662px;
            height: 60px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 0.3199999928474426px;
            font-family: AlibabaPuHuiTiR;
            line-height: 30px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 8px 0 0 7px;
          }
          .iwm-word8 {
            width: 65px;
            height: 22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(40, 40, 40, 1);
            font-size: 16px;
            letter-spacing: 0.3199999928474426px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 22px;
            text-align: left;
            margin: 54px 0 0 7px;
          }
          .iwm-infoBox2 {
            width: 662px;
            height: 90px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 0.3199999928474426px;
            font-family: AlibabaPuHuiTiR;
            line-height: 30px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 8px 0 0 7px;
          }
        }
      }
    }
    .itm-main7 {
      height: 428px;
      border: 1px solid rgba(245, 245, 245, 1);
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.04);
      width: 1161px;
      margin: 45px 0 0 329px;
      .itm-layer1 {
        width: 1122px;
        height: 428px;
        .itm-group2 {
          z-index: 70;
          position: relative;
          width: 428px;
          height: 428px;
          overflow: hidden;
          background: url(../../assets/images/back/grayfour.png) 100% no-repeat;
          .itm-img2 {
            z-index: 71;
            position: absolute;
            left: 79px;
            top: 106px;
            width: 260px;
            height: 216px;
          }
        }
        .itm-group3 {
          width: 670px;
          height: 366px;
          .itm-outer1 {
            width: 275px;
            height: 70px;
            .itm-group4 {
              height: 70px;
              background-color: rgba(37, 184, 47, 1);
              width: 64px;
              .itm-txt4 {
                width: 20px;
                height: 44px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(255, 255, 255, 1);
                font-size: 32px;
                letter-spacing: 0.6399999856948853px;
                font-family: AlibabaPuHuiTiM;
                white-space: nowrap;
                line-height: 44px;
                text-align: left;
                margin: 18px 0 0 23px;
              }
            }
            .itm-group5 {
              width: 193px;
              height: 41px;
              margin-top: 26px;
              .itm-txt5 {
                width: 193px;
                height: 33px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(51, 51, 51, 1);
                font-size: 24px;
                letter-spacing: 0.47999998927116394px;
                font-family: AlibabaPuHuiTiR;
                white-space: nowrap;
                line-height: 33px;
                text-align: left;
              }
              .itm-mod1 {
                width: 65px;
                height: 2px;
                background-color: rgba(37, 184, 47, 1);
                margin-top: 6px;
              }
            }
          }
          .itm-txt6 {
            width: 33px;
            height: 22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(40, 40, 40, 1);
            font-size: 16px;
            letter-spacing: 0.3199999928474426px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 22px;
            text-align: left;
            margin: 32px 0 0 8px;
          }
          .itm-paragraph1 {
            width: 662px;
            height: 90px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 0.3199999928474426px;
            font-family: AlibabaPuHuiTiR;
            line-height: 30px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 8px 0 0 8px;
          }
          .itm-word11 {
            width: 65px;
            height: 22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(40, 40, 40, 1);
            font-size: 16px;
            letter-spacing: 0.3199999928474426px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 22px;
            text-align: left;
            margin: 24px 0 0 8px;
          }
          .itm-paragraph2 {
            width: 657px;
            height: 90px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 0.3199999928474426px;
            font-family: AlibabaPuHuiTiR;
            line-height: 30px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 8px 0 0 8px;
          }
        }
      }
    }
    .itm-main8 {
      height: 428px;
      border: 1px solid rgba(245, 245, 245, 1);
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.04);
      width: 1161px;
      margin: 60px 0 0 430px;
      .itm-group6 {
        width: 1097px;
        height: 428px;
        margin-left: 64px;
        .itm-block3 {
          width: 645px;
          height: 396px;
          .itm-bd1 {
            width: 195px;
            height: 70px;
            margin-left: 450px;
            .itm-box2 {
              width: 113px;
              height: 41px;
              margin-top: 26px;
              .itm-word12 {
                width: 97px;
                height: 33px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(51, 51, 51, 1);
                font-size: 24px;
                letter-spacing: 0.47999998927116394px;
                font-family: AlibabaPuHuiTiR;
                white-space: nowrap;
                line-height: 33px;
                text-align: left;
                margin-left: 16px;
              }
              .itm-main9 {
                width: 113px;
                height: 2px;
                background-color: rgba(37, 184, 47, 1);
                margin-top: 6px;
              }
            }
            .itm-box3 {
              height: 70px;
              background-color: rgba(37, 184, 47, 1);
              width: 64px;
              .itm-txt7 {
                width: 20px;
                height: 44px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(255, 255, 255, 1);
                font-size: 32px;
                letter-spacing: 0.6399999856948853px;
                font-family: AlibabaPuHuiTiM;
                white-space: nowrap;
                line-height: 44px;
                text-align: left;
                margin: 18px 0 0 23px;
              }
            }
          }
          .itm-word13 {
            width: 33px;
            height: 22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(40, 40, 40, 1);
            font-size: 16px;
            letter-spacing: 0.3199999928474426px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 22px;
            text-align: left;
            margin-top: 32px;
          }
          .itm-infoBox1 {
            width: 629px;
            height: 90px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 0.3199999928474426px;
            font-family: AlibabaPuHuiTiR;
            line-height: 30px;
            text-align: left;
            margin-top: 8px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .itm-word14 {
            width: 65px;
            height: 22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(40, 40, 40, 1);
            font-size: 16px;
            letter-spacing: 0.3199999928474426px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 22px;
            text-align: left;
            margin-top: 24px;
          }
          .itm-infoBox2 {
            width: 629px;
            height: 120px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 0.3199999928474426px;
            font-family: AlibabaPuHuiTiR;
            line-height: 30px;
            text-align: left;
            margin-top: 8px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .itm-block4 {
          z-index: 73;
          position: relative;
          width: 428px;
          height: 428px;
          overflow: hidden;
          background: url(../../assets/images/back/grayfour.png) 100% no-repeat;
          .itm-img3 {
            z-index: 74;
            position: absolute;
            left: 3px;
            top: 138px;
            width: 421px;
            height: 163px;
          }
        }
      }
    }
    .isc-mod6 {
      height: 428px;
      border: 1px solid rgba(245, 245, 245, 1);
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.04);
      width: 1161px;
      margin: 45px 0 0 329px;
      .isc-main1 {
        width: 1122px;
        height: 428px;
        .isc-box1 {
          z-index: 61;
          position: relative;
          width: 428px;
          height: 428px;
          overflow: hidden;
          background: url(../../assets/images/back/grayfour.png) 100% no-repeat;
          .isc-pic2 {
            z-index: 62;
            position: absolute;
            left: 124px;
            top: 102px;
            width: 179px;
            height: 222px;
          }
        }
        .isc-box2 {
          width: 669px;
          height: 366px;
          .isc-box3 {
            width: 251px;
            height: 70px;
            .isc-section2 {
              height: 70px;
              background-color: rgba(37, 184, 47, 1);
              width: 64px;
              .isc-word8 {
                width: 20px;
                height: 44px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(255, 255, 255, 1);
                font-size: 32px;
                letter-spacing: 0.6399999856948853px;
                font-family: AlibabaPuHuiTiM;
                white-space: nowrap;
                line-height: 44px;
                text-align: left;
                margin: 18px 0 0 23px;
              }
            }
            .isc-section3 {
              width: 169px;
              height: 41px;
              margin-top: 26px;
              .isc-word9 {
                width: 169px;
                height: 33px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(51, 51, 51, 1);
                font-size: 24px;
                letter-spacing: 0.47999998927116394px;
                font-family: AlibabaPuHuiTiR;
                white-space: nowrap;
                line-height: 33px;
                text-align: left;
              }
              .isc-outer2 {
                width: 65px;
                height: 2px;
                background-color: rgba(37, 184, 47, 1);
                margin-top: 6px;
              }
            }
          }
          .isc-txt4 {
            width: 33px;
            height: 22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(40, 40, 40, 1);
            font-size: 16px;
            letter-spacing: 0.3199999928474426px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 22px;
            text-align: left;
            margin: 32px 0 0 7px;
          }
          .isc-paragraph1 {
            width: 662px;
            height: 90px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 0.3199999928474426px;
            font-family: AlibabaPuHuiTiR;
            line-height: 30px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 8px 0 0 7px;
          }
          .isc-info6 {
            width: 65px;
            height: 22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(40, 40, 40, 1);
            font-size: 16px;
            letter-spacing: 0.3199999928474426px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 22px;
            text-align: left;
            margin: 24px 0 0 7px;
          }
          .isc-paragraph2 {
            width: 662px;
            height: 90px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 0.3199999928474426px;
            font-family: AlibabaPuHuiTiR;
            line-height: 30px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 8px 0 0 7px;
          }
        }
      }
    }
    .icr-box4 {
      height: 428px;
      border: 1px solid rgba(245, 245, 245, 1);
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.04);
      width: 1161px;
      margin: 45px 0 0 329px;
      .icr-section2 {
        width: 1122px;
        height: 428px;
        .icr-main2 {
          z-index: 61;
          position: relative;
          width: 428px;
          height: 428px;
          overflow: hidden;
          background: url(../../assets/images/back/grayfour.png) 100% no-repeat;
          .icr-img2 {
            z-index: 62;
            position: absolute;
            left: 100px;
            top: 123px;
            width: 226px;
            height: 190px;
          }
        }
        .icr-main3 {
          width: 669px;
          height: 366px;
          .icr-mod3 {
            width: 203px;
            height: 70px;
            .icr-wrap1 {
              height: 70px;
              background-color: rgba(37, 184, 47, 1);
              width: 64px;
              .icr-txt2 {
                width: 20px;
                height: 44px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(255, 255, 255, 1);
                font-size: 32px;
                letter-spacing: 0.6399999856948853px;
                font-family: AlibabaPuHuiTiM;
                white-space: nowrap;
                line-height: 44px;
                text-align: left;
                margin: 18px 0 0 23px;
              }
            }
            .icr-wrap2 {
              width: 121px;
              height: 41px;
              margin-top: 26px;
              .icr-word11 {
                width: 121px;
                height: 33px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(51, 51, 51, 1);
                font-size: 24px;
                letter-spacing: 0.47999998927116394px;
                font-family: AlibabaPuHuiTiR;
                white-space: nowrap;
                line-height: 33px;
                text-align: left;
              }
              .icr-outer3 {
                width: 65px;
                height: 2px;
                background-color: rgba(37, 184, 47, 1);
                margin-top: 6px;
              }
            }
          }
          .icr-txt3 {
            width: 33px;
            height: 22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(40, 40, 40, 1);
            font-size: 16px;
            letter-spacing: 0.3199999928474426px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 22px;
            text-align: left;
            margin: 32px 0 0 7px;
          }
          .icr-infoBox1 {
            width: 662px;
            height: 90px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 0.3199999928474426px;
            font-family: AlibabaPuHuiTiR;
            line-height: 30px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 8px 0 0 7px;
          }
          .icr-info5 {
            width: 65px;
            height: 22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(40, 40, 40, 1);
            font-size: 16px;
            letter-spacing: 0.3199999928474426px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 22px;
            text-align: left;
            margin: 24px 0 0 7px;
          }
          .icr-paragraph1 {
            width: 662px;
            height: 90px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 0.3199999928474426px;
            font-family: AlibabaPuHuiTiR;
            line-height: 30px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 8px 0 0 7px;
          }
        }
      }
    }
    .ins-mod5 {
      height: 428px;
      border: 1px solid rgba(245, 245, 245, 1);
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.04);
      width: 1161px;
      margin: 45px 0 0 329px;
      .ins-group1 {
        width: 1122px;
        height: 428px;
        .ins-block1 {
          z-index: 61;
          position: relative;
          width: 428px;
          height: 428px;
          overflow: hidden;
          background: url(../../assets/images/back/grayfour.png) 100% no-repeat;
          .ins-pic2 {
            z-index: 62;
            position: absolute;
            left: 35px;
            top: 135px;
            width: 360px;
            height: 155px;
          }
        }
        .ins-block2 {
          width: 669px;
          height: 366px;
          .ins-box1 {
            width: 203px;
            height: 70px;
            .ins-outer3 {
              height: 70px;
              background-color: rgba(37, 184, 47, 1);
              width: 64px;
              .ins-word10 {
                width: 20px;
                height: 44px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(255, 255, 255, 1);
                font-size: 32px;
                letter-spacing: 0.6399999856948853px;
                font-family: AlibabaPuHuiTiM;
                white-space: nowrap;
                line-height: 44px;
                text-align: left;
                margin: 18px 0 0 23px;
              }
            }
            .ins-outer4 {
              width: 121px;
              height: 41px;
              margin-top: 26px;
              .ins-word11 {
                width: 121px;
                height: 33px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(51, 51, 51, 1);
                font-size: 24px;
                letter-spacing: 0.47999998927116394px;
                font-family: AlibabaPuHuiTiR;
                white-space: nowrap;
                line-height: 33px;
                text-align: left;
              }
              .ins-bd4 {
                width: 65px;
                height: 2px;
                background-color: rgba(37, 184, 47, 1);
                margin-top: 6px;
              }
            }
          }
          .ins-txt4 {
            width: 33px;
            height: 22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(40, 40, 40, 1);
            font-size: 16px;
            letter-spacing: 0.3199999928474426px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 22px;
            text-align: left;
            margin: 32px 0 0 7px;
          }
          .ins-infoBox1 {
            width: 662px;
            height: 90px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 0.3199999928474426px;
            font-family: AlibabaPuHuiTiR;
            line-height: 30px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 8px 0 0 7px;
          }
          .ins-info4 {
            width: 65px;
            height: 22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(40, 40, 40, 1);
            font-size: 16px;
            letter-spacing: 0.3199999928474426px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 22px;
            text-align: left;
            margin: 24px 0 0 7px;
          }
          .ins-paragraph1 {
            width: 662px;
            height: 90px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 0.3199999928474426px;
            font-family: AlibabaPuHuiTiR;
            line-height: 30px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 8px 0 0 7px;
          }
        }
      }
    }
    .ima-group6 {
      height: 428px;
      border: 1px solid rgba(245, 245, 245, 1);
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.04);
      width: 1161px;
      margin: 45px 0 0 329px;
      .ima-layer2 {
        width: 1122px;
        height: 428px;
        .ima-bd4 {
          position: relative;
          width: 428px;
          height: 428px;
          overflow: hidden;
          background: url(../../assets/images/back/grayfour.png) 100% no-repeat;
          .ima-pic2 {
            position: absolute;
            left: 38px;
            top: 149px;
            width: 365px;
            height: 108px;
          }
        }
        .ima-bd5 {
          width: 669px;
          height: 366px;
          .ima-box1 {
            width: 227px;
            height: 70px;
            .ima-outer1 {
              height: 70px;
              background-color: rgba(37, 184, 47, 1);
              width: 64px;
              .ima-word6 {
                width: 20px;
                height: 44px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(255, 255, 255, 1);
                font-size: 32px;
                letter-spacing: 0.6399999856948853px;
                font-family: AlibabaPuHuiTiM;
                white-space: nowrap;
                line-height: 44px;
                text-align: left;
                margin: 18px 0 0 23px;
              }
            }
            .ima-outer2 {
              width: 145px;
              height: 41px;
              margin-top: 26px;
              .ima-word7 {
                width: 145px;
                height: 33px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(51, 51, 51, 1);
                font-size: 24px;
                letter-spacing: 0.47999998927116394px;
                font-family: AlibabaPuHuiTiR;
                white-space: nowrap;
                line-height: 33px;
                text-align: left;
              }
              .ima-section1 {
                width: 65px;
                height: 2px;
                background-color: rgba(37, 184, 47, 1);
                margin-top: 6px;
              }
            }
          }
          .ima-info3 {
            width: 33px;
            height: 22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(40, 40, 40, 1);
            font-size: 16px;
            letter-spacing: 0.3199999928474426px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 22px;
            text-align: left;
            margin: 32px 0 0 7px;
          }
          .ima-infoBox1 {
            width: 662px;
            height: 90px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 0.3199999928474426px;
            font-family: AlibabaPuHuiTiR;
            line-height: 30px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 8px 0 0 7px;
          }
          .ima-info4 {
            width: 65px;
            height: 22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(40, 40, 40, 1);
            font-size: 16px;
            letter-spacing: 0.3199999928474426px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 22px;
            text-align: left;
            margin: 24px 0 0 7px;
          }
          .ima-infoBox2 {
            width: 662px;
            height: 90px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 0.3199999928474426px;
            font-family: AlibabaPuHuiTiR;
            line-height: 30px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 8px 0 0 7px;
          }
        }
      }
    }
    .ima-group7 {
      height: 428px;
      border: 1px solid rgba(245, 245, 245, 1);
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.04);
      width: 1161px;
      margin: 60px 0 0 430px;
      .ima-layer3 {
        width: 1097px;
        height: 428px;
        margin-left: 64px;
        .ima-block1 {
          width: 645px;
          height: 366px;
          .ima-box2 {
            width: 203px;
            height: 70px;
            margin-left: 442px;
            .ima-bd6 {
              width: 121px;
              height: 41px;
              margin-top: 26px;
              .ima-info5 {
                width: 121px;
                height: 33px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(51, 51, 51, 1);
                font-size: 24px;
                letter-spacing: 0.47999998927116394px;
                font-family: AlibabaPuHuiTiR;
                white-space: nowrap;
                line-height: 33px;
                text-align: right;
              }
              .ima-block2 {
                width: 113px;
                height: 2px;
                background-color: rgba(37, 184, 47, 1);
                margin: 6px 0 0 8px;
              }
            }
            .ima-bd7 {
              height: 70px;
              background-color: rgba(37, 184, 47, 1);
              width: 64px;
              .ima-word8 {
                width: 20px;
                height: 44px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(255, 255, 255, 1);
                font-size: 32px;
                letter-spacing: 0.6399999856948853px;
                font-family: AlibabaPuHuiTiM;
                white-space: nowrap;
                line-height: 44px;
                text-align: left;
                margin: 18px 0 0 23px;
              }
            }
          }
          .ima-word9 {
            width: 33px;
            height: 22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(40, 40, 40, 1);
            font-size: 16px;
            letter-spacing: 0.3199999928474426px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 22px;
            text-align: left;
            margin-top: 32px;
          }
          .ima-paragraph1 {
            width: 629px;
            height: 90px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 0.3199999928474426px;
            font-family: AlibabaPuHuiTiR;
            line-height: 30px;
            text-align: left;
            margin-top: 8px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .ima-word10 {
            width: 65px;
            height: 22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(40, 40, 40, 1);
            font-size: 16px;
            letter-spacing: 0.3199999928474426px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 22px;
            text-align: left;
            margin-top: 24px;
          }
          .ima-infoBox3 {
            width: 629px;
            height: 90px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 0.3199999928474426px;
            font-family: AlibabaPuHuiTiR;
            line-height: 30px;
            text-align: left;
            margin-top: 8px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .ima-block3 {
          z-index: 11;
          position: relative;
          width: 428px;
          height: 428px;
          overflow: hidden;
          background: url(../../assets/images/back/grayfour.png) 100% no-repeat;
          .ima-pic3 {
            z-index: 12;
            position: absolute;
            left: 37px;
            top: 148px;
            width: 328px;
            height: 166px;
          }
        }
      }
    }
    // 结束
    .ins-mod3 {
      width: 1172px;
      height: 44px;
      margin: 120px 0 0 372px;
      .ins-info2 {
        width: 140px;
        height: 44px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 32px;
        letter-spacing: 0.6399999856948853px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 44px;
        text-align: left;
      }
      .ins-word6 {
        width: 128px;
        height: 44px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 32px;
        letter-spacing: 0.6399999856948853px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 44px;
        text-align: left;
      }
      .ins-word7 {
        width: 131px;
        height: 44px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 32px;
        letter-spacing: 0.6399999856948853px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 44px;
        text-align: left;
      }
      .ins-word8 {
        width: 132px;
        height: 44px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 32px;
        letter-spacing: 0.6399999856948853px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 44px;
        text-align: left;
      }
      .ins-word9 {
        width: 125px;
        height: 44px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(37, 184, 47, 1);
        font-size: 32px;
        letter-spacing: 0.6399999856948853px;
        font-family: AlibabaPuHuiTiM;
        white-space: nowrap;
        line-height: 44px;
        text-align: left;
      }
      .ins-info3 {
        width: 148px;
        height: 44px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 32px;
        letter-spacing: 0.6399999856948853px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 44px;
        text-align: left;
      }
    }
    .ins-mod4 {
      width: 40px;
      height: 6px;
      border-radius: 3px;
      background-color: rgba(37, 184, 47, 1);
      margin: 2px 0 0 1242px;
    }
    .ins-mod6 {
      z-index: 65;
      height: 599px;
      overflow: hidden;
      // background: url(../../assets/images/back/whiteinstance.png)
      //   100% no-repeat;
      background: url(../../assets/images/partner/more.png) 100% no-repeat;
      margin-top: 120px;
      width: 1920px;
      position: relative;
      .ins-bd5 {
        width: 129px;
        height: 44px;
        margin: 80px 0 0 896px;
        .ins-word12 {
          width: 129px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(39, 39, 39, 1);
          font-size: 32px;
          letter-spacing: 0.6399999856948853px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 44px;
          text-align: left;
        }
      }
      .ins-bd6 {
        width: 1263px;
        height: 93px;
        margin: 40px 0 0 329px;
        .ins-section1 {
          z-index: 68;
          position: relative;
          width: 335px;
          height: 93px;
          border-radius: 12px;
          overflow: hidden;
          background: url(../../assets/images/back/whiteshadow.png) -20px -10px
            no-repeat;
          .ins-layer1 {
            z-index: 69;
            position: absolute;
            left: 5px;
            top: 27px;
            width: 330px;
            height: 41px;
            background: url(../../assets/images/partner/zhongchuan.png) -20px -10px
              no-repeat;
          }
        }
        .ins-section2 {
          z-index: 107;
          position: relative;
          width: 200px;
          height: 93px;
          border-radius: 12px;
          overflow: hidden;
          background: url(../../assets/images/back/whitesmall.png) -20px -10px
            no-repeat;
          .ins-outer5 {
            z-index: 108;
            position: absolute;
            left: 9px;
            top: 4px;
            width: 182px;
            height: 85px;
            background: url(../../assets/images/partner/cssc.png) -20px -10px
              no-repeat;
          }
        }
        .ins-section3 {
          z-index: 104;
          position: relative;
          width: 200px;
          height: 93px;
          border-radius: 12px;
          overflow: hidden;
          background: url(../../assets/images/back/whitesmall.png) -20px -10px
            no-repeat;
          .ins-section4 {
            z-index: 105;
            position: absolute;
            left: -8px;
            top: -3px;
            width: 217px;
            height: 100px;
            background: url(../../assets/images/partner/jingdong.png) -12px -7px
              no-repeat;
          }
        }
        .ins-section5 {
          z-index: 110;
          position: relative;
          width: 200px;
          height: 93px;
          border-radius: 12px;
          overflow: hidden;
          background: url(../../assets/images/back/whitesmall.png) -20px -10px
            no-repeat;
          .ins-group2 {
            z-index: 111;
            position: absolute;
            left: 1px;
            top: -28px;
            width: 200px;
            height: 150px;
            background: url(../../assets/images/partner/sony.png) -20px 18px no-repeat;
          }
        }
        .ins-section6 {
          z-index: 113;
          position: relative;
          width: 200px;
          height: 93px;
          border-radius: 12px;
          overflow: hidden;
          background: url(../../assets/images/back/whitesmall.png) -20px -10px
            no-repeat;
          .ins-box2 {
            z-index: 114;
            position: absolute;
            left: 17px;
            top: 17px;
            width: 166px;
            height: 60px;
            background: url(../../assets/images/partner/jinchang.png) -20px -11px
              no-repeat;
          }
        }
      }
      .ins-bd7 {
        width: 1264px;
        height: 71px;
        margin: 36px 0 0 329px;
        .ins-main3 {
          z-index: 71;
          position: relative;
          width: 200px;
          height: 71px;
          border-radius: 12px;
          overflow: hidden;
          background: url(../../assets/images/partner/whitesma.png) -20px -10px
            no-repeat;
          .ins-outer6 {
            z-index: 72;
            position: absolute;
            left: -7px;
            top: 5px;
            width: 207px;
            height: 63px;
            background: url(../../assets/images/partner/nanfang.png) -13px -10px
              no-repeat;
          }
        }
        .ins-main4 {
          z-index: 95;
          position: relative;
          width: 200px;
          height: 71px;
          border-radius: 12px;
          overflow: hidden;
          background: url(../../assets/images/partner/whitesma.png) -20px -10px
            no-repeat;
          .ins-section7 {
            z-index: 96;
            position: absolute;
            left: -32px;
            top: -6px;
            width: 254px;
            height: 83px;
            background: url(../../assets/images/partner/ryoka.png) 12px -4px no-repeat;
          }
        }
        .ins-main5 {
          z-index: 98;
          position: relative;
          width: 200px;
          height: 71px;
          border-radius: 12px;
          overflow: hidden;
          background: url(../../assets/images/partner/whitesma.png) -20px -10px
            no-repeat;
          .ins-box3 {
            z-index: 99;
            position: absolute;
            left: 1px;
            top: -59px;
            width: 200px;
            height: 200px;
            background: url(../../assets/images/partner/inspur.png) -20px 50px no-repeat;
          }
        }
        .ins-main6 {
          z-index: 101;
          position: relative;
          width: 200px;
          height: 71px;
          border-radius: 12px;
          overflow: hidden;
          background: url(../../assets/images/partner/whitesma.png) -20px -10px
            no-repeat;
          .ins-pic3 {
            z-index: 102;
            position: absolute;
            left: -20px;
            top: -10px;
            width: 240px;
            height: 111px;
          }
        }
        .ins-main7 {
          z-index: 74;
          position: relative;
          width: 200px;
          height: 71px;
          border-radius: 12px;
          overflow: hidden;
          background: url(../../assets/images/partner/whitesma.png) -20px -10px
            no-repeat;
          .ins-mod7 {
            z-index: 75;
            position: absolute;
            left: -2px;
            top: 0;
            width: 214px;
            height: 77px;
            background: url(../../assets/images/partner/jichang.png) -17px -10px
              no-repeat;
          }
        }
      }
      .ins-bd8 {
        width: 1262px;
        height: 117px;
        margin: 36px 0 82px 337px;
        .ins-wrap1 {
          z-index: 77;
          position: relative;
          width: 122px;
          height: 117px;
          border-radius: 12px;
          overflow: hidden;
          background: url(../../assets/images/partner/whitefang.png) -20px -10px
            no-repeat;
          .ins-main8 {
            z-index: 78;
            position: absolute;
            left: 0;
            top: -4px;
            width: 127px;
            height: 122px;
            background: url(../../assets/images/partner/geesun.png) -20px -5px
              no-repeat;
          }
        }
        .ins-wrap2 {
          z-index: 80;
          position: relative;
          width: 122px;
          height: 117px;
          border-radius: 12px;
          overflow: hidden;
          background: url(../../assets/images/partner/whitefang.png) -20px -10px
            no-repeat;
          .ins-layer2 {
            z-index: 81;
            position: absolute;
            left: 13px;
            top: 31px;
            width: 96px;
            height: 56px;
            background: url(../../assets/images/partner/csscsma.png) -20px -11px
              no-repeat;
          }
        }
        .ins-wrap3 {
          z-index: 83;
          position: relative;
          width: 122px;
          height: 117px;
          border-radius: 12px;
          overflow: hidden;
          background: url(../../assets/images/partner/whitefang.png) -20px -10px
            no-repeat;
          .ins-outer7 {
            z-index: 84;
            position: absolute;
            left: -12px;
            top: -24px;
            width: 155px;
            height: 167px;
            background: url(../../assets/images/partner/crsc.png) -8px 15px no-repeat;
          }
        }
        .ins-wrap4 {
          z-index: 86;
          position: relative;
          width: 122px;
          height: 117px;
          border-radius: 12px;
          overflow: hidden;
          background: url(../../assets/images/partner/whitefang.png) -20px -10px
            no-repeat;
          .ins-wrap5 {
            z-index: 87;
            position: absolute;
            left: 2px;
            top: 6px;
            width: 118px;
            height: 105px;
            background: url(../../assets/images/partner/geely.png) -11px 1px no-repeat;
          }
        }
        .ins-wrap6 {
          z-index: 89;
          position: relative;
          width: 172px;
          height: 117px;
          border-radius: 12px;
          overflow: hidden;
          background: url(../../assets/images/partner/whitemid.png) -20px -10px
            no-repeat;
          .ins-bd9 {
            z-index: 90;
            position: absolute;
            left: -15px;
            top: -5px;
            width: 202px;
            height: 143px;
            background: url(../../assets/images/partner/hik.png) -5px -5px
              no-repeat;
          }
        }
        .ins-wrap7 {
          z-index: 92;
          position: relative;
          width: 172px;
          height: 117px;
          border-radius: 12px;
          overflow: hidden;
          background: url(../../assets/images/partner/whitemid.png) -20px -10px
            no-repeat;
          .ins-box4 {
            z-index: 93;
            position: absolute;
            left: -3px;
            top: 15px;
            width: 179px;
            height: 90px;
            background: url(../../assets/images/partner/iflytek.png) -17px -10px
              no-repeat;
          }
        }
      }
      .ins-pic4 {
        z-index: 66;
        position: absolute;
        left: 173px;
        top: 53px;
        width: 1638px;
        height: 546px;
      }
    }
    .ins-mod8 {
      height: 527px;
      background-color: rgba(38, 38, 38, 1);
      width: 1920px;
      .ins-wrap8 {
        width: 1520px;
        height: 362px;
        margin: 81px 0 0 201px;
        .ins-main9 {
          width: 1015px;
          height: 36px;
          margin-left: 128px;
          .ins-word13 {
            width: 38px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            letter-spacing: 0.8571428656578064px;
            font-family: AlibabaPuHuiTiM;
            line-height: 36px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .ins-txt5 {
            width: 38px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            letter-spacing: 0.8571428656578064px;
            font-family: AlibabaPuHuiTiM;
            line-height: 36px;
            text-align: left;
            margin-left: 156px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .ins-word14 {
            width: 38px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            letter-spacing: 0.8571428656578064px;
            font-family: AlibabaPuHuiTiM;
            line-height: 36px;
            text-align: left;
            margin-left: 116px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .ins-txt6 {
            width: 38px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            letter-spacing: 0.8571428656578064px;
            font-family: AlibabaPuHuiTiM;
            line-height: 36px;
            text-align: left;
            margin-left: 116px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .ins-info5 {
            width: 87px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 21px;
            letter-spacing: 1px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 36px;
            text-align: left;
            margin-left: 388px;
          }
        }
        .ins-main10 {
          width: 1138px;
          height: 56px;
          margin: 20px 0 0 128px;
          .ins-group3 {
            width: 73px;
            height: 56px;
            .ins-txt7 {
              width: 73px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .ins-txt8 {
              width: 64px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
          }
          .ins-group4 {
            width: 67px;
            height: 56px;
            margin-left: 121px;
            .ins-info6 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .ins-info7 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
          }
          .ins-word15 {
            width: 67px;
            height: 24px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 0.761904776096344px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 24px;
            text-align: left;
            margin-left: 87px;
          }
          .ins-word16 {
            width: 67px;
            height: 24px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 0.761904776096344px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 24px;
            text-align: left;
            margin-left: 87px;
          }
          .ins-word17 {
            width: 210px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 0.761904776096344px;
            font-family: AlibabaPuHuiTiR;
            line-height: 36px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 16px 0 0 359px;
          }
        }
        .ins-main11 {
          width: 1262px;
          height: 88px;
          margin: 8px 0 0 128px;
          .ins-layer3 {
            width: 77px;
            height: 88px;
            .ins-info8 {
              width: 68px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .ins-word18 {
              width: 77px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
            .ins-info9 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
          }
          .ins-layer4 {
            width: 67px;
            height: 56px;
            margin-left: 117px;
            .ins-txt9 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .ins-word19 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
          }
          .ins-layer5 {
            width: 334px;
            height: 84px;
            margin-left: 667px;
            .ins-txt10 {
              width: 334px;
              height: 36px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              line-height: 36px;
              text-align: left;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .ins-word20 {
              width: 220px;
              height: 36px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              line-height: 36px;
              text-align: left;
              margin-top: 12px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        .ins-txt11 {
          width: 68px;
          height: 24px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 0.761904776096344px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 24px;
          text-align: left;
          margin: 8px 0 0 128px;
        }
        .ins-info10 {
          width: 632px;
          height: 24px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 0.8);
          font-size: 14px;
          letter-spacing: 1.75px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 24px;
          text-align: left;
          margin: 81px 0 0 443px;
        }
        .ins-main12 {
          width: 1520px;
          height: 1px;
          margin-top: 16px;
        }
      }
    }
  }
}
</style>
<style src="../../assets/css/common.css" />
